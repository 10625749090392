import { environment } from '../../environments/environment';

export const AppConfig = {
  apiUrl: environment.apiUrl,
  version: '1.29.1',
  isTest: !environment.production && environment.isTest,
  defaultStorageMode: 'local',
  cambioValutaHUF: 330.02,
  googleClientId: '317999483364-j6bjhe366jip40i3h7mc2a5tccqaqsi8.apps.googleusercontent.com',
  bugsnagApiKey: 'e9ae2772d321253c0a0dbace17dadba9',
};
