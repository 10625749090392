import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MwebCoreService } from './mweb-core.service';
import { MwebPopAlertService } from './mweb-popalert.service';
import { MwebStorageService } from './mweb-storage.service';

@Injectable()
export class MwebHttpInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private coreService: MwebCoreService,
    private popAlertService: MwebPopAlertService,
    private storageService: MwebStorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Imposta il base url
    const baseUrl = AppConfig.apiUrl;
    let modifiedRequest = request.clone({ url: baseUrl + request.url });

    // Imposta gli headers
    modifiedRequest = this.normalizeRequestHeaders(modifiedRequest);

    this.coreService.startHttpRunning();

    return next.handle(modifiedRequest).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate(['/auth/login/'], { relativeTo: this.route });
          } else if (error.status === 503) {
            this.router.navigate(['/auth/maintenance/'], { relativeTo: this.route });
            this.handleError(error);
          } else {
            this.handleError(error);
          }
        }

        const errorObservable = new Subject<any>();
        errorObservable.complete();
        return errorObservable;
        // return throwError(error);
      }),
      finalize(() => this.coreService.stopHttpRunning())
    );
  }

  // region: --- request

  private normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let modifiedHeaders = new HttpHeaders();
    modifiedHeaders = this.addAuthorizationHeaders(modifiedHeaders);

    return request.clone({
      headers: modifiedHeaders,
    });
  }

  private addAuthorizationHeaders(headers: HttpHeaders): HttpHeaders {
    const token = this.storageService.get(ConfigSession.token);
    if (token) {
      headers = headers.set(ConfigSession.token, token);
    }

    return headers;
  }

  // endregion

  // region: --- response

  private handleError(errorResponse: HttpErrorResponse) {
    // Caso in cui il server ritorna errori generici, non gestiti dall'app
    if (errorResponse.status === 0 || errorResponse.status === 500 || !errorResponse.error || errorResponse.error.message === '') {
      let errorMessage = errorResponse?.error?.message || errorResponse.message;
      if (environment.production) {
        errorMessage = errorResponse.statusText;
      }

      this.popAlertService.showHttpMessages(errorMessage);
      return;
    }

    if (errorResponse.error) {
      // Caso in cui il server ritorna un errore string (es errori gestiti dall'app)
      if (typeof errorResponse.error === 'string') {
        this.popAlertService.showHttpMessages(errorResponse.error);
        return;
      }

      // Caso in cui il server ritorna un errore per una chiamta che si aspetta un file di download
      if (errorResponse.error instanceof Blob) {
        errorResponse.error.text().then((err) => {
          this.popAlertService.showHttpMessages(err);
        });
        return;
      }

      // Caso in cui il server ritorna un msg d'errore dentro la proprietà message: lo metto dentro un array per poi ciclarlo
      const errors = [];
      errors.push(errorResponse.error.message);

      // Caso in cui il server ritorna un array di errori string (es le validazioni)
      for (const property in errorResponse.error) {
        if (errorResponse.error.hasOwnProperty(property)) {
          const propertyErrors: string[] = errorResponse.error[property];
          if (typeof propertyErrors !== 'string' && propertyErrors.length > 0) {
            propertyErrors.forEach((error) => {
              if (typeof error === 'string') {
                errors.push(error);
              }
            });
          }
        }
      }

      const errorMessage = errors.filter((error) => error).join('<br />');
      this.popAlertService.showErrorMessage(errorMessage);
    }
  }

  // endregion
}
