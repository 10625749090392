import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MwebPopAlertComponent } from './components/mweb-popalert.component';
import { AdminGuardService } from './services/admin-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { MwebCoreService } from './services/mweb-core.service';
import { MwebHttpInterceptor } from './services/mweb-http-interceptor';
import { MwebHttpService } from './services/mweb-http.service';
import { MwebPopAlertService } from './services/mweb-popalert.service';
import { MwebStateService } from './services/mweb-state.service';
import { MwebStorageService } from './services/mweb-storage.service';
import { MwebSyncService } from './services/mweb-sync.service';
import { PermissionGuardService } from './services/permission-guard.service';
import { PwaService } from './services/pwa-service.service';

@NgModule({
  imports: [HttpClientModule, CommonModule],
  declarations: [MwebPopAlertComponent],
  exports: [MwebPopAlertComponent],
  providers: [
    MwebCoreService,
    MwebHttpService,
    MwebPopAlertService,
    MwebStorageService,
    MwebStateService,
    MwebSyncService,
    AuthGuardService,
    AdminGuardService,
    PermissionGuardService,
    PwaService,
    { provide: HTTP_INTERCEPTORS, useClass: MwebHttpInterceptor, multi: true },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
