import { Injectable } from '@angular/core';
import { SYNC_TOKEN } from '@shared/event-tokens';
import { MwebEventService } from './mweb-event.service';
import { MwebHttpService } from './mweb-http.service';
import { MwebPopAlertService } from './mweb-popalert.service';
import { MwebStateService } from './mweb-state.service';

@Injectable()
export class MwebSyncService {
  get canTenantSync(): boolean {
    const tenant = this.stateService.state?.profilo?.tenant;
    if (tenant === TenantType.renovaenvironment) {
      return true;
    }

    return false;
  }

  syncInit: boolean;

  constructor(
    private httpService: MwebHttpService,
    private popAlert: MwebPopAlertService,
    private stateService: MwebStateService,
    private eventService: MwebEventService
  ) {}

  /**
   * Avvia la sincronizzazione periodica / automatica
   *
   * @returns {void}
   * @memberof MwebSyncService
   */
  sincronizzaCloud(): void {
    if (!this.canTenantSync) {
      return;
    }

    this.syncInit = true;
    this.httpService.get('/cloud/sync').subscribe((response) => {
      this.pushSyncLogs();
      if (response.clienti) {
        this.popAlert.showInfoMessage('Sincronizzazione avvenuta con successo');
      }
    });
  }

  /**
   * Forza la sincronizzazione
   *
   * @returns {void}
   * @memberof MwebSyncService
   */
  sincronizzaCloudForce(): void {
    if (!this.canTenantSync) {
      return;
    }

    this.httpService.get('/cloud/sync-f').subscribe((_) => {
      this.pushSyncLogs();
      this.popAlert.showInfoMessage('Sincronizzazione avvenuta con successo');
    });
  }

  /**
   * Sincronizzazione manuale anno precedente
   *
   * @returns {void}
   * @memberof MwebSyncService
   */
  sincronizzaCloudFattureAcquistoAnnoPrecedente(): void {
    if (!this.canTenantSync) {
      return;
    }

    this.httpService.get('/cloud/sync-fatture-acquisto-anno-precedente').subscribe((_) => {
      this.pushSyncLogs();
      this.popAlert.showInfoMessage('Sincronizzazione avvenuta con successo');
    });
  }

  // Notifica ai componenti che la sync è stata completata (anche in caso di errore)
  private pushSyncLogs(): void {
    this.eventService.dispatch(SYNC_TOKEN, true);
  }
}
