import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class BusinessMessage {
  severity: string;
  message: string;
}

export const MessageTypes = {
  Information: {
    Severity: 'info',
    Description: '',
  },
  Warning: {
    Severity: 'warn',
    Description: '',
  },
  Error: {
    Severity: 'danger',
    Description: 'Operazione non eseguita',
  },
  Success: {
    Severity: 'success',
    Description: 'Operazione eseguita con successo',
  },
};

@Injectable()
export class MwebPopAlertService {
  private _message$: Subject<BusinessMessage>;

  constructor() {
    this._message$ = new Subject<BusinessMessage>();
  }

  get message$(): Observable<BusinessMessage> {
    return this._message$.asObservable();
  }

  private showMessages(messages: BusinessMessage): void {
    this._message$.next(messages);
  }

  showHttpMessages(body: string): void {
    let message = body;
    if (typeof body === 'object') {
      message = body[0] as string;
    }

    this.showErrorMessage(body.substr(0, 200));
  }

  showSuccessMessage(): void {
    const message = new BusinessMessage();
    message.severity = MessageTypes.Success.Severity;
    message.message = MessageTypes.Success.Description;
    this.showMessages(message);
  }

  showInfoMessage(msg: string): void {
    const message = new BusinessMessage();
    message.severity = MessageTypes.Information.Severity;
    message.message = msg;
    this.showMessages(message);
  }

  showWarningMessage(msg: string): void {
    const message = new BusinessMessage();
    message.severity = MessageTypes.Warning.Severity;
    message.message = msg;
    this.showMessages(message);
  }

  showErrorMessage(msg?: string): void {
    const message = new BusinessMessage();
    message.severity = MessageTypes.Error.Severity;
    if (msg) {
      message.message = msg;
    } else {
      message.message = MessageTypes.Error.Description;
    }
    this.showMessages(message);
  }
}
