import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Observer, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { InjectionToken } from '@angular/core';

@Injectable()
export class MwebCntxService implements OnDestroy {
  private _destroy$ = new Subject<void>();
  private _: {
    [_key: string]: BehaviorSubject<unknown | undefined>;
  } = {};

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  provide<T>(key: InjectionToken<T>, initVal?: T): Observer<T> {
    const _key = key.toString();
    if (!(_key in this._)) {
      this._[_key] = new BehaviorSubject<T | undefined>(initVal);
    } else if (initVal) {
      this._[_key].next(initVal);
    }

    const _bs = this._[_key];
    return {
      next: _bs.next.bind(_bs),
      error: _bs.error.bind(_bs),
      complete: _bs.complete.bind(_bs)
    };
  }

  get$<T>(key: InjectionToken<T>): Observable<T> {
    const _key = key.toString();
    if (!(_key in this._)) {
      this._[_key] = new BehaviorSubject<T | undefined>(undefined);
    }
    return (this._[_key].asObservable() as Observable<T>).pipe(
      filter(t => t !== undefined),
      takeUntil(this._destroy$)
    );
  }

  curr<T>(key: InjectionToken<T>): T | undefined {
    const _key = key.toString();
    if (_key in this._) {
      return this._[_key].getValue() as T | undefined;
    } else {
      return undefined;
    }
  }
}
